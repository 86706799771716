import React, { useEffect, useState } from 'react';

// CSS import
import '../../assets/css/InputComponents/selectInput.css';

// Firebase imports
import Firebase from 'firebase';
import firebase from '../../config/Firebase';

// Assets imports
import SetLocalIcon from '../../assets/imgs/set_local_icon.svg';

import MapInput from '../MapInput';
import Loading from '../Loading';

export function SelectLocalEventInput({ title, field, tradeAdmin, userCity, value, setValue, objectPend, doc, role, disable }) {
  const [type, setType] = useState(handleSetType());
	const [valuesSelect, setValuesSelect] = useState();
	const [geolocation, setGeolocation] = useState(handleGeolocationItem(field));
	const [center, setCenter] = useState();

	function handleSetType() {
		if (objectPend) {
			if (objectPend.currDoc[field] === "")
				return "map";

      return "local";
		}
    
		if (!doc || doc.data()[field] === undefined)
			return "local";

		if (doc.data()[field] !== "")
			return "local";

		return "map";
	}

  async function handleLocationCity() {
		const docs = await firebase.firestore().collection('Cities')
			.where('name', '==', userCity)
			.get();

		setCenter(docs.docs[0].data().location);
  }

  function handleGeolocationItem(fieldGeo) {
    if (objectPend) {
			if (objectPend.currDoc[fieldGeo] !== "")
				return "";

      return `${objectPend.currDoc.location.latitude}, ${objectPend.currDoc.location.longitude}`;
		}
    
    if (doc && doc.data().location)
      return `${doc.data().location.latitude}, ${doc.data().location.longitude}`;
		
    return "";
  }

	async function handleAttractions() {
    const attractions = [];
    let docs;
    
    if (role === 'tradeAdmin') {
      for (let index = 0; index < tradeAdmin.length; index++) {
        // eslint-disable-next-line no-await-in-loop
        const attr = await firebase.firestore().collection('Attractions').doc(tradeAdmin[index]).get();

        attractions.push({ title: attr.data().Title, value: tradeAdmin[index] });
      }
    } else {
      docs = await firebase.firestore().collection('Attractions')
        .where('City', '==', userCity)
        .where('Active', '==', true)
        .orderBy('Title')
        .get();
      
      docs.docs.map((attr) => {
        attractions.push({ title: attr.data().Title, value: attr.id });
      });
    }

    setValuesSelect(attractions);
  }

	function SelectInput() {
		return (
			<select onChange={(event) => setValue({ ...value, [field]: event.target.value })} id={field + 'Form'} disabled={disable} defaultValue={value[field]} >
				<option value=''>Selecione uma opção</option>
				{ 
					valuesSelect.length > 0 ? valuesSelect.map((e, index) => {
						return <option key={'Select' + field + index} value={e.value}>{e.title}</option>
					}) : <option disabled>Sem opções</option>
				}
			</select>
		)
	}

	function validateCord(cord) {
    if (cord >= -180 && cord <= 180) return true;

    return false;
  }

	const handleGeopoint = (e, field) => {
    const list = e.split(',');

    if (list.length > 0) {
      if (list.length === 2 && validateCord(Number(list[0])) && validateCord(Number(list[1]))) {
        setValue({ ...value, location: new Firebase.firestore.GeoPoint(Number(list[0]), Number(list[1])) });
      }
    }
  };

	function handleOpenMap(idElement) {
    if (window.google) {
      const element = document.getElementById(idElement);

      if (element.clientHeight === 0)
        element.style = 'height: 70vh; margin-top: 1rem; max-height: 400px;';
      else
        handleCloseMap(idElement);
    } else {
      const element = document.getElementById(idElement);

      if (element.clientHeight === 0)
        element.style = 'height: 10vh; margin-top: 1rem';
      else
        handleCloseMap(idElement);
    }
  }

	function handleCloseMap(idElement) {
    document.getElementById(idElement).style = 'height: 0; margin-top: 0; max-height: 400px;';
  }

	useEffect(() => {
		handleAttractions();
	}, []);

	useEffect(() => {
    handleGeopoint(geolocation, field);
    if (geolocation === '')
      delete value.location;
  }, [geolocation]);

	useEffect(() => {
		handleLocationCity();
	}, []);

	if (!valuesSelect || !center)
		return <Loading />;

  return (
    <div className='waypointInput'>
      <div className='div_align_input_geolocation'>
        <div className='div_label_input_geolocation'>
          <label className='label_text_input'>
            <strong>{title}</strong>
          </label>
        </div>
        <div className='div_explanation_waypoint_input'>
          <div className="div_align_button_type_waypoint">
            <div id={type === 'local' ? 'typeSelectWaypoint' : 'typeUnselectWaypoint'} className="div_button_type_waypoint" onClick={() => { setValue({ ...value, [field]: "" }); setGeolocation(""); setType('local'); }}>
              <span>Local</span>
            </div>
            <div id={type === 'map' ? 'typeSelectWaypoint' : 'typeUnselectWaypoint'} className="div_button_type_waypoint" onClick={() => { setValue({ ...value, [field]: "" }); setGeolocation(""); setType('map') }}>
              <span>Mapa</span>
            </div>
          </div>
          {
            type === 'local' ?
						<div className='div_select_input_select' style={{ width: "100%" }}>
							<SelectInput />
						</div>
						:
						<div style={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "center",	minHeight: "80px" }} className='div-align-map-select-local-event'>
							<div className='div_field_input_geolocation'>
								<input className='input_geolocation' id={field + 'Form'} type="text" value={geolocation} onChange={(e) => setGeolocation(e.target.value) } placeholder={"Localizar no Google Maps, clicar com botão direito e colar os dados. Ex: -23.22222, -47.44444"}/>
								<button type='button' className='btn_open_map' onClick={() => handleOpenMap("location" + 'Map')}>
									<img src={SetLocalIcon} alt='Abrir Mapa' className='img_map_btn'/>
								</button>
							</div>
							<MapInput center={center} id={"location"} setValue={setGeolocation} />
						</div>
          }
        </div>
      </div>
    </div>
  );
}